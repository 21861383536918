import React, { Component } from 'react';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import {
  Grid,
  Row,
  Col,
  Button,
  FormGroup,
  ControlLabel,
  FormControl
} from 'react-bootstrap';
import Listing from './listing';
import request from '../../utils/Request';

class Listings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      properties: [],
      rentedProperties: [],
      search: {
        min: 0,
        max: 100000,
        bedroom: 0,
        bathroom: 1,
        city: 'BurnabySurreyVancouverRichmondNew-WestministerNorth-VancouverCoquitlamDelta'
      }
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.clearSearchCriteria = this.clearSearchCriteria.bind(this);
  }

  componentDidMount() {
    this.loadSearchCriteria();
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState(
      prev => ({ search: { ...prev.search, [name]: value } }),
      this.loadProperties
    );
    this.saveSearchCriteria(name, value);
  }

  loadProperties() {
    let data = this.state.search;
    request.post('/propertylisting/search', data).then(properties => {
      if (properties) {
        this.setState({
          properties: properties
        });
      } else {
        this.setState({
          properties: []
        });
      }
    });

    request.get('/propertylisting/recentlyrented')
    .then(rentedProperties => {
      if (rentedProperties) {
        this.setState({
          rentedProperties: rentedProperties
        });
      } else {
        this.setState({
          rentedProperties: []
        })
      }
    })
    .catch((err) => {
      console.log(err)
    })
  }

  saveSearchCriteria(name, value) {
    localStorage.setItem(name, value);
  }

  loadSearchCriteria() {
    const min = localStorage.getItem('min');
    const max = localStorage.getItem('max');
    const bedroom = localStorage.getItem('bedroom');
    const bathroom = localStorage.getItem('bathroom');
    const city = localStorage.getItem('city');

    this.setState(prevState => {
      const search = { ...prevState.search };

      if (min !== null) {
        search.min = min;
      }
      if (max !== null) {
        search.max = max;
      }
      if (bedroom !== null) {
        search.bedroom = bedroom;
      }
      if (bathroom !== null) {
        search.bathroom = bathroom;
      }
      if (city !== null) {
        search.city = city;
      }

      return { search };
    }, this.loadProperties);
  }

  clearSearchCriteria() {
    localStorage.removeItem('min');
    localStorage.removeItem('max');
    localStorage.removeItem('bedroom');
    localStorage.removeItem('bathroom');
    localStorage.removeItem('city');

    this.setState(
      {
        search: {
          min: 0,
          max: 100000,
          bedroom: 0,
          bathroom: 1,
          city: 'BurnabySurreyVancouverRichmondNew-WestministerNorth-VancouverCoquitlamDelta'
        }
      },
      this.loadProperties
    );
  }

  buildOptions() {
    const arr = [];
    for (let i = 400; i <= 3000; i = i + 50) {
      arr.push(
        <option key={i} value={i}>
          ${i}.00
        </option>
      );
    }
    return arr;
  }

  render() {
    const isSSR = typeof window === 'undefined';
    const recentlyRentedAmount = 12;
    return (
      <>
        {!isSSR && (
          <Layout>
            <SEO
              title="Listings | Korecki Real Estate Services"
              description="Find Properties for Rent through us. We provide professional, experienced Strata Management services to clients throughout the Lower Mainland."
              keywords="korecki,Real Estate,Strata Management services, Rent, Properties, Property Management , Strata Property Management, Strata Management, Property Management, Client Property Account System, Listings, Property Listings"
            />
            <div>
              <section
                className="page-header page-header-light page-header-more-padding"
                style={{ marginTop: '0px' }}>
                <Grid>
                  <Row>
                    <Col md={12}>
                      <h1>
                        Rental Listings
                        <span>
                          Properties for Rent - {this.state.properties.length}{' '}
                          properties
                        </span>
                      </h1>
                    </Col>
                  </Row>
                  <Row className="mt-lg">
                    <Col md={2}>
                      <FormGroup controlId="formControlsSelect">
                        <ControlLabel>Price Min</ControlLabel>
                        <FormControl
                          name="min"
                          componentClass="select"
                          placeholder="select"
                          value={this.state.search.min}
                          onChange={this.handleInputChange}>
                          <option value="0">No Min</option>
                          {this.buildOptions()}
                        </FormControl>
                      </FormGroup>
                    </Col>
                    <Col md={2}>
                      <FormGroup controlId="formControlsSelect">
                        <ControlLabel>Price Max</ControlLabel>
                        <FormControl
                          name="max"
                          componentClass="select"
                          placeholder="select"
                          value={this.state.search.max}
                          onChange={this.handleInputChange}>
                          <option value="100000">No Max</option>
                          {this.buildOptions()}
                        </FormControl>
                      </FormGroup>
                    </Col>
                    <Col md={2}>
                      <FormGroup controlId="formControlsSelect">
                        <ControlLabel>Bedrooms</ControlLabel>
                        <FormControl
                          name="bedroom"
                          componentClass="select"
                          placeholder="select"
                          value={this.state.search.bedroom}
                          onChange={this.handleInputChange}>
                          <option value="0">0+</option>
                          <option value="1">1+</option>
                          <option value="2">2+</option>
                          <option value="3">3+</option>
                          <option value="4">4+</option>
                          <option value="5">5+</option>
                          <option value="6">6+</option>
                          <option value="7">7+</option>
                          <option value="8">8+</option>
                        </FormControl>
                      </FormGroup>
                    </Col>
                    <Col md={2}>
                      <ControlLabel>Bathrooms</ControlLabel>
                      <FormGroup controlId="formControlsSelect">
                        <FormControl
                          name="bathroom"
                          componentClass="select"
                          placeholder="select"
                          value={this.state.search.bathroom}
                          onChange={this.handleInputChange}>
                          <option value="1">1+</option>
                          <option value="2">2+</option>
                          <option value="3">3+</option>
                          <option value="4">4+</option>
                          <option value="5">5+</option>
                          <option value="6">6+</option>
                          <option value="7">7+</option>
                          <option value="8">8+</option>
                        </FormControl>
                      </FormGroup>
                    </Col>
                    <Col md={2}>
                      <ControlLabel>City</ControlLabel>
                      <FormGroup controlId="formControlsSelect">
                        <FormControl
                          name="city"
                          componentClass="select"
                          placeholder="select"
                          value={this.state.search.city}
                          onChange={this.handleInputChange}>
                          <option value="BurnabySurreyVancouverRichmondNew-WestministerNorth-VancouverCoquitlamDelta">
                            Any City
                          </option>
                          <option value="Burnaby">Burnaby</option>
                          <option value="Surrey">Surrey</option>
                          <option value="Vancouver">Vancouver</option>
                          <option value="Richmond">Richmond</option>
                          <option value="New-Westminister">
                            New Westminister
                          </option>
                          <option value="North-Vancouver">
                            North Vancouver
                          </option>
                          <option value="Coquitlam">
                            Coquitlam
                          </option>
                          <option value="Delta">
                            Delta
                          </option>
                        </FormControl>
                      </FormGroup>
                    </Col>
                    <Col md={2}>
                      <Button
                        type="submit"
                        onClick={this.clearSearchCriteria}
                        className="btn btn-primary custom-btn-style-1 text-uppercase font-weight-semibold pull-right">
                        Clear filters
                      </Button>
                    </Col>
                  </Row>
                </Grid>
              </section>
              <Grid className="properties-listing">
                <Row>
                  {this.state &&
                    this.state.properties &&
                    this.state.properties.map(listing => {
                      return (
                        <Col md={4} sm={6} xs={12} key={listing.id}>
                          <Listing listing={listing} />
                        </Col>
                      );
                    })}
                </Row>
              </Grid>
              <section className="page-header page-header-light secondary-section"
                style={{ marginTop: '0px', marginBottom: '0px' }}>
                <Grid>
                  <Row>
                    <Col md={12}>
                      <h1 style={{marginBottom: "-2px"}}>
                        Recently Rented Properties
                      </h1>
                    </Col>
                  </Row>
                </Grid>
                <Grid className="properties-listing">
                  <Row>
                    {this.state &&
                      this.state.rentedProperties &&
                      this.state.rentedProperties.slice(0,recentlyRentedAmount).map(listing => {
                        return (
                          <Col md={4} sm={6} xs={12} key={listing.id + 1}>
                            <Listing listing={listing} />
                          </Col>
                        );
                      })}
                  </Row>
                </Grid>
              </section>
            </div>
          </Layout>
        )}
      </>
    );
  }
}

export default Listings;
